import Types from "../type";

const initialState = {
    data : [],
    blogId: '',
}

function blogReducer (state=initialState, action){
    switch(action.type){
        case Types.SHOWBLOG:
            return{
                ...state,
                data: [...state.data, action.payload],
            }
        default:
            return state;
    }
}

export default blogReducer;
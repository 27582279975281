import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const RecentPostCard = ({ data }) => {
    const maxWords = 5;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const truncatedTitle = data.title.split(' ').slice(0, maxWords).join(' ') + (data.title.split(' ').length > maxWords ? '...' : '');
    const displayTitle = isExpanded ? data.title : truncatedTitle;
    return (
        <div className="recent-post-card">
            <Link to={`/blogs/blog-single/${data?.id}`} className="recent-post-card__link">
                <div className="recent-post-card__img">
                    <img src={data.image || ''} alt="/" />
                </div>
                <p className="recent-post-card__content">
                    {displayTitle || ''}
                    {/* {!isExpanded && data.title.split(' ').length > maxWords && (
                        <button onClick={toggleExpansion} className="read-more-button">
                            Read More
                        </button>
                    )} */}
                </p>
            </Link>
        </div>
    )
}

export default RecentPostCard;
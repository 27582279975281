import { Monitor, ArrowRight } from "react-feather";

const WorkCards = ({data}) => {
    return (
        <div className="work-card">
            <div className="work-card__top">
                <span className="work-card__icon">
                    {/* {data.icon || ''} */}
                    <img src={data.icon || ''} alt="/"/>
                </span>
                <span className="mini-text">Step {data.step || ''}</span>
            </div>
            <div className="work-card__content">
                <h2 className="sub-subtitle">{data.title || ''}</h2>
                <p>{data.content || ''}</p>
            </div>
            <span className="arrow">
                <ArrowRight />
            </span>
        </div>
    )
}

export default WorkCards;
import { useState } from "react";

//newletter API
import { newsletterAPI } from '../../services/ApiServices';


//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// axios
import axios from "axios";

const toastOptions = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const Newsletter = () => {
    const [inputData, setInputData] = useState({ email: '' });
    const [loader, setLoader] = useState(false);


    const handleInput = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!inputData || !inputData.email) {
            return toast.error('Check your Email', { ...toastOptions, transition: Bounce });
        }

        setLoader(true);
        try {
            const res = await axios.post(newsletterAPI, inputData);
            const successMessage = res.data.message;
            toast.success(successMessage, toastOptions);
            setInputData({ email: '' });
        } catch (err) {
            let errorMessage = 'An error occurred';
            if (err.response && err.response.data && err.response.data.email && err.response.data.email[0]) {
                errorMessage = err.response.data.email[0];
            }
            toast.error(errorMessage, { ...toastOptions, transition: Bounce });
        } finally {
            setLoader(false);
        }
    }

    return (
        <div className="newletter">
            <input type="email" name="email" placeholder="Email" value={inputData.email} onChange={handleInput} required />

            {loader ?
                <span className='small-loader'>
                    <svg width="30" height="30" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#f6f6f6" data-testid="oval-svg">
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#f6f6f68a" strokeWidth="3"></circle>
                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                </path>
                            </g>
                        </g>
                    </svg>
                </span>
                :
                <button className="btn-light" data-text="Subscribe"  onClick={handleSubmit}><span className="btn-wrapper">Subscribe</span></button>
            }


        </div>
    )
}

export default Newsletter;
import CenterContent from "../CenterContent";
import JobImg from "./img/Slice 1.png"

const OurAI = () => {
    // Define the data
    const titleText = 'How our AI does the job';
    const spanText = 'our AI';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);

    return (
        <section className="our-ai">
            <div className="container">
                <div className="our-ai__wrapper">
                    <CenterContent miniText="Our AI" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Harness the power of advanced artificial intelligence and cutting-edge technology to transform your data into actionable insights." />


                </div>
            </div>
            <div className='our-ai__img'>
                <img src={JobImg} alt='InsightAI' />
            </div>
        </section>
    )
}

export default OurAI;
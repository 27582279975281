import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//images
// import SeedFund from './img/startup-removebg-preview.png';
// import StartupSchool from './img/startup-school__1_-removebg-preview.png';
// import FounderHub from './img/Microsoft-Founder-removebg-preview.png';
// import Kurativz from './img/kurativz-removebg-preview.png';

import MySQL from './img/my-sql.png'; 
import PostgreSQL from './img/postgres.png';
import Redshift from './img/redshift.png';
import SQLAnywhere from './img/sap.jpeg';
import MongoDB from './img/mongo.png'
import SQLite from './img/sql-lite.png';
import Snowflake from './img/snowflakes.png'
import Oracle from './img/oracle.png';
import SQLServer from './img/msq-server.png';
import Cassandra from './img/cassandra.webp'
import Elasticsearch from './img/elastic.png'
import BigQuery from './img/postgres.png'
import Teradata from './img/teradata.png'
import DB2 from './img/db2.png';
import DynamoDB from './img/DynamoDB.png'
import HBase from './img/Hbase.png'
import Hive from './img/hive.png'
import Impala from './img/Impala.png'
import Neo4j from './img/neo-removebg-preview.png'
import Redis from './img/Redis.png'
import InfluxDB from './img/influx.png'
import CouchDB from './img/couch.webp'
import MariaDB from './img/maria.png'
import SalesForce from './img/sales (1).png'
import AzureSynapse from './img/synapse.png';

const Recognitions = () => {
    const settings = {
        dots: false,
        arrows: false, // Set arrows to false to remove the arrows
        infinite: true,
        speed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }
        ],
    };
    return (
        <div className="recognitions">
            <div className="container">
                <div className="recognitions__wrapper">
                    <Slider {...settings}>
                        {/* <div className="recognitions__icons seed-fund">
                            <img src={SeedFund} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons kurativz">
                            <img src={Kurativz} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons startup">
                            <img src={StartupSchool} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={FounderHub} alt="Founderhub recognised" />
                        </div> */}
                        <div className="recognitions__icons">
                            <img src={MySQL} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={PostgreSQL} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={Redshift} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons sap">
                            <img src={SQLAnywhere} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={MongoDB} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons sql-lite">
                            <img src={SQLite} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons snowflakes">
                            <img src={Snowflake} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={Oracle} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={SQLServer} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={Cassandra} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={Elasticsearch} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={BigQuery} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={Teradata} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={DB2} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={DynamoDB} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={HBase} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={Hive} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons impala">
                            <img src={Impala} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons neo">
                            <img src={Neo4j} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={Redis} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={InfluxDB} alt="Seed Fund recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={CouchDB} alt="Kurativz recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={MariaDB} alt="Startup School recognised" />
                        </div>

                        <div className="recognitions__icons">
                            <img src={SalesForce} alt="Founderhub recognised" />
                        </div>
                        <div className="recognitions__icons">
                            <img src={AzureSynapse} alt="Seed Fund recognised" />
                        </div>
                        
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Recognitions;

import CenterContent from "../CenterContent";
import TeamCard from "./teamCard";

//data
import { teamData } from "./team-data";

const Teams = () => {
    // Define the data
    const titleText = "We're a results driven team";
    const spanText = 'results driven';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);

    return (
        <div className="our-teams">
            <div className="container">
                <CenterContent miniText="Team members" title={titleText} spanText={spanText} spanIndex={spanIndex} content="With a passion for innovation and a dedication to excellence, we bring diverse expertise to every project we undertake." />
                <div className="our-teams__wrapper">
                    {
                        teamData &&
                        teamData.map((item, i)=>{
                            return(
                                <TeamCard key={i} name={item.name || ''} position={item.position || ''} linkedIn={item.linkedIn || ''} twitter={item.twitter || ''} img={item.img || ''} instagram={item.instagram}/>
                            )
                        })
                    }

                </div>

            </div>
        </div>
    )
}

export default Teams;
import { useState } from "react";

import CenterContent from "../CenterContent"
import IntegrationCard from "./integrationCard";
import {integrationData} from "./integrationData.js"

const Integration = () => {
    const [visibleBlogs, setVisibleBlogs] = useState(6);

    const handleViewMore = () => {
        setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 3);
    };

    const handleViewLess = () => {
        setVisibleBlogs((prevVisibleBlogs) => Math.max(prevVisibleBlogs - 3, 6));
    };

    // Define the data
    const titleText = 'Effortless Integration with all the apps you use';
    const spanText = 'Effortless Integration';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <section className="integration">
            <div className="container">
                <CenterContent miniText="PLUGIN INTEGRATION" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Seamlessly connect with leading bookkeeping or e-commerce software, eliminating manual data downloads and streamlining your workflow." />
                <div className="integration__grid">
                    {
                    integrationData.slice(0, visibleBlogs).map((item,i)=>{
                        return(
                            <IntegrationCard img={item.img} title={item.title} content={item.content} />
                        )
                    })
                    }
                </div>

                <div className="view-btn-wrapper">
                    {visibleBlogs < integrationData.length && (
                        <button onClick={handleViewMore} className='btn' data-text="View More"><span className="btn-wrapper">View More</span></button>
                    )}
                    {visibleBlogs > 6 && (
                        <button onClick={handleViewLess} className='btn' data-text="View Less"><span className="btn-wrapper">View Less</span></button>
                    )}
                </div>
            </div>

        </section>
    )
}

export default Integration;
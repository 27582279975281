import DummyImg from './img/hero.jpeg';
import { Check } from 'react-feather';
const ImageTextCol = ({ data, id, reverseClass }) => {
    return (
        <div className={data?.reverse ? "image-text reverse" : "image-text"} id={data.id || ''}>
            <div className="container">
                <div className="image-text__content">
                    {/* <span className="mini-text">{data.subtitle || ''}</span> */}
                    <h2 className="title">{data.title || ''}</h2>
                    {/* <p>{data.content || ''}</p> */}

                    {
                        data.list &&
                        <ul className='image-text__list'>
                            {

                                data.list.map((item, i) => {
                                    const [boldText, normalText] = item.split(': ');
                                    return (
                                        <li key={i}><strong>{boldText}:</strong> {normalText}</li>
                                    )

                                })
                            }
                        </ul>
                    }


                </div>
                <div className="image-text__image">
                    <img src={data.img} alt="/" />
                </div>
            </div>
        </div>
    )
}
export default ImageTextCol;
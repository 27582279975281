const ContactInfo = () => {
    return (
        <div className="contact-info">
            <div className="container">
                <div className="address-block">
                    <h2 className="subtitle">Address</h2>

                    <div className="address-block__addresses">
                        <div className="address">
                            <h2 className="sub-subtitle">Noida</h2>
                            <address>
                                <span>IHDP Business Park</span>
                                <span>Plot No. 7, Sector 127</span>
                                <span>Noida, Uttar Pradesh 201303</span>
                            </address>
                        </div>
                        <div className="address">
                            <h2 className="sub-subtitle">New Delhi</h2>
                            <address>
                                <span>B1/H3, Block B</span>
                                <span>Mohan Cooperative Industrial Estate</span>
                                <span>New Delhi 110044</span>
                            </address>
                        </div>
                    </div>

                </div>
                <div className="contact-information">
                    <h2 className="subtitle">Contact Us:</h2>
                    <div className="contact-information__wrapper">
                        <div className="phone-wrapper">
                            {/* <a href="tel:+91-9901306996" target="_blank" rel="noopener noreferrer" className="phone">+91-9901306996</a> */}
                            <a href="tel:+91-7768944118" target="_blank" rel="noopener noreferrer" className="phone">+91-7768944118</a>
                        </div>

                        <div className="mail-wrapper">
                            {/* <a href="mailto:rashmi@insightai.in" target="_blank" rel="noopener noreferrer" className="mail">rashmi@insightai.in</a> */}
                            <a href="mailto:akash@insightai.in" target="_blank" rel="noopener noreferrer" className="mail">akash@insightai.in</a>
                            <a href="mailto:sales@insightai.in" target="_blank" rel="noopener noreferrer" className="mail">sales@insightai.in</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;
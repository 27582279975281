import placeHolder from './img/palceholder.jpg';

//feather
import { Linkedin, Instagram } from "react-feather";
import Twitter from './img/twitter-x.png';

const TeamCard = ({ name, position, linkedIn, twitter, img, instagram }) => {
    return (
        <div className="teams-card">
            <div className="teams-card__wrapper">
                <img src={img || placeHolder} alt='/team 1' />

                <div className='teams-card__info'>
                    <h2 className='teams-card__member-name'>{name || ''}</h2>
                    <span className='position'>{position || ''}</span>
                    <div className='teams-card__social-links'>
                        <a href={linkedIn || "https://www.linkedin.com/company/insightai-technologies/"} target="_blank" rel="noopener noreferrer" className="teams-card__link"><Linkedin /></a>
                        {
                            (twitter !== '')
                                ?
                                <a href={twitter || "https://twitter.com/getInsightAI"} target="_blank" rel="noopener noreferrer" className="teams-card__link"><img src={Twitter} alt="twitter" /></a>
                                : ''
                        }


                        {
                            (instagram !== '')
                                ?
                                <a href={instagram || "https://www.facebook.com/profile.php/?id=61552129252904"} target="_blank" rel="noopener noreferrer" className="teams-card__link"><Instagram /></a>
                                : ''
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamCard;
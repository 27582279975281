import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import ProfessionalJourney from "../../components/ProfessionalJourney";
import Teams from "../../components/Teams";
import VideoBlock from "../../components/VideoBlock";

const AboutUs = () => {
    // Define the data
    const titleText = 'The Team Crafting Our Success';
    const spanText = 'Team';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <>
            <InnerPageBanner miniText="About Us" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Our expert team combines AI, data analytics, and fintech skills to create innovative solutions that revolutionize the financial industry." />
            <VideoBlock />
            {/* <ProfessionalJourney /> */}
            <Teams />
        </>
    )
}

export default AboutUs;
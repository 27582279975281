import { Link } from "react-router-dom";
// import ImageFilter from "./ImageFilter";
//image
// import ScaleUpImge from '../../assets/banner/scale_up.jpg';
import ScaleUpIamge from './img/4944669-removebg-preview.png';

import TargetImage from '../../assets/banner/target.jpg';
import pattern from './img/pattern.svg';


const Visuals = () =>{
    return(
        <section className="visuals">
            {/* <div className="visuals__bg">
                <span className="pattern">
                    <img src={pattern} alt='/' />
                </span>
            </div> */}
            <div className="container">
                <div className="visuals__content">
                    <span className="mini-text ">Accelerated Success</span>
                    <h2 className="title">Customers Experience 70% Time Savings and <span>3x Revenue Growth</span> with Our Cutting-Edge Solutions!</h2>
                    <p className="large-paragraph">Experience unparalleled efficiency and growth. Our innovative solutions save 70% of your time and boost revenue by 3x, transforming your financial operations and driving success.</p>
                    <a className='btn' href="https://app.insightai.in/register" target="_blank" rel="noopener noreferrer" data-text="Get started for Free!"><span className='btn-wrapper'>Get started for Free!</span></a>
                </div>
                <div className="visuals__graphic">
                    {/* <ImageFilter /> */}
                    <img src={ScaleUpIamge} alt="/" />
                </div>
            </div>
        </section>
    )
}

export default Visuals;
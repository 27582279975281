import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogSlide from "./BlogSlide";
import CenterContent from "../CenterContent"

import { ChevronLeft, ChevronRight } from 'react-feather';
//data

import { blogData } from './data';

const BlogSlider = () => {
    // Custom arrow component for previous arrow
    const CustomPrevArrow = ({ onClick }) => (
        <button className="slick-prev" onClick={onClick}>
            {/* <ChevronLeft /> */}
        </button>
    );

    // Custom arrow component for next arrow
    const CustomNextArrow = ({ onClick }) => (
        <button className="slick-next" onClick={onClick}>
            {/* <ChevronRight /> */}
        </button>
    );
    const settings = {
        dots: false,
        arrows: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,

        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    }

    // Define the data
    const titleText = 'Our Blogs';
    const spanText = 'Our Blogs';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);



    return (
        <div className="blog-slider">

            <div className="container">
                <CenterContent miniText="Our Blogs" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Enhance your experience with our range of add-ons, designed to further optimize your process." />
            </div>

            <div className="blog-slider-wrapper">
                <Slider {...settings}>
                    {
                        blogData.map(item => {
                            return (
                                <BlogSlide data={item} />
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default BlogSlider;
import FeatureCount from "./featureCounts";

const KeyBenefits = ()=>{
    return(
        <section className="key-benefits">
            <div className="container">
                <div className="key-benefits__content">
                    <span className="mini-text">key benefits</span>
                    <h2 className="title">Optimiztion and Efficiency</h2>
                    <p>Showcase key performance metrics with animated counters highlighting time savings, cost reduction, data accuracy, and compliance checks.</p>

                    <ul>
                        <li>Up to 30% of the time spent on manual data entry.</li>
                        <li>Reduce operational costs by 30% through automation.</li>
                        <li>High accuracy in data extraction and analysis.</li>
                        <li>Automated compliance checks ensure adherence.</li>

                    </ul>
                </div>
                <FeatureCount />
            </div>
        </section>
    )
}

export default KeyBenefits;
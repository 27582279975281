import { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import HorizontalNav from './components/Navigation/horizontal/index'
import VerticalNav from './components/Navigation/vertical/index';
// import Banner from './components/Banner';
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './routes/Routes';
import Footer from './components/Footer';
import ScrollToTopOnMount from './utils/scrollToTop';
// import Offers from './components/Offers';

function App() {
  const [isSidebarActive, setSidebarIsActive] = useState(false);
  const toggleSidebar =()=>{
    setSidebarIsActive(prev => !prev);
  }
  return (
    <Router>
      <div className='clear-fix'></div>
      <ScrollToTopOnMount />
      <HorizontalNav isSidebarActive={isSidebarActive} setSidebarIsActive={toggleSidebar} />
      <VerticalNav isSidebarActive={isSidebarActive} onClose={toggleSidebar}/>
      {/* <Offers /> */}
      <MyRoutes />
      <Footer />

      <ToastContainer />
    </Router>

  );
}

export default App;
import React from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

const Pagination = ({ data, itemsPerPage, onPageChange }) => {
  // Calculate the total number of pages
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Handle page click event
  const handlePageClick = (selectedPage) => {
    onPageChange(selectedPage.selected);
  };
  return (
    <ReactPaginate
      pageCount={pageCount}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      previousLabel={<ChevronLeft />}
      nextLabel={<ChevronRight />}
      activeClassName={'active'}
    />
  );
};

export default Pagination;

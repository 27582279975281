import ContactForm from "./contactForm";
import ContactMap from "./contactMap";
import ContactInfo from "../../components/ContactInfo";

const ContactUs = () => {
    return (
        <>
            <section className="contact">
                <div className="contact__inner">
                    <ContactForm />
                    <ContactMap />
                </div>
            </section>
            <ContactInfo />
        </>

    )
}

export default ContactUs;
import { useState } from 'react';

//Animation

import { TypeAnimation } from 'react-type-animation';
import { ReactTyped } from 'react-typed';

//icons
import { Play } from 'react-feather';

import pattern from './img/pattern.svg';
// import bannerImg from './img/hero.jpeg';
// import bannerImg from './img/home-page.webp';
import bannerImg from './img/new-bot.png';
// import bannerImg from '../../assets/feature/reconciliation.png';

// import PHImg from './img/product-hunt.svg';
// import SIImg from './img/SI.png';
// import ONDCImg from './img/ondc.png';
import Tally from './img/tally.png';
import Zoho from './img/zoho.png';
import FresBooks from './img/freshBooks.png';
import Logo from './img/logo.png';



// import Newsletter from '../NewsLetter';
import VideoModal from '../Modals/VideoModal';


const Banner = () => {
    const [showVideoModal, setShowVideoModal] = useState(false);

    const toggleVideoModal = () => {
        setShowVideoModal(prev => !prev);
    }
    return (
        <section className="home-banner">
            {/* Background Pattern */}
            {/* <div className="background-pattern">
                <span className="bg-solid"></span>
                <span className="bg-pattern-wrapper">
                    <img src={pattern} alt="" />
                </span>
            </div> */}

            <div className="container">
                <div className="home-banner__content-box">
                    {/* <div className="home-banner__logo">
                        <img src={Logo} alt="product hunt, InsightAI" /> Insight<span style={{ display: 'inline-block', fontWeight: '400', color: '#37d4ad' }}>AI</span></div> */}
                    <h1 className="main-title">
                        {/* AI Powered {" "}
                        <ReactTyped strings={["Document Intelligence", "Data Analytics", "Fraud Detection", "CRM Integration", "Gen AI Bot"]} typeSpeed={100} loop /> */}

                        Your AI assistant for Finance
                    </h1>
                    <div className='home-banner__services'>
                        <ReactTyped strings={["Fraud and Compliance Team", "Sales Team", "Revenue Team", "Operations Team"]} typeSpeed={60} loop />
                    </div>
                    <div className="home-banner__description">
                        {/* <p className="large-paragraph">
                            Advanced AI solutions for secure, compliant financial operations
                        </p> */}
                        <div className='home-banner__btn-wrapper'>
                            <a className='btn-border' href="https://app.insightai.in/register" target="_blank" rel="noopener noreferrer" data-text="Get started for Free!"><span className='btn-wrapper'>Get started for Free!</span></a>
                            <div className='watch-video'>
                                <span className='watch-video__title'>Watch Video</span>
                                <button className='play-btn' onClick={toggleVideoModal}><Play /></button>
                            </div>
                        </div>

                    </div>

                    <div className='banner-tags'>
                        {/* <ProductHunt /> */}
                        <a href="https://www.producthunt.com/posts/insightai-2?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-insightai&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=467026&theme=dark" alt="InsightAI - Your&#0032;Digital&#0032;CFO&#0044;&#0032;Automated&#0032;Intelligent&#0032;Financial&#0032;Management | Product Hunt" style={{ width: "250px", height: "54px", width: "250", height: "54", marginRight: '10px' }} /></a>


                        {/* <a className='banner-tags__tag SI' href="https://ondc.org/" target="_blank" rel="noopener noreferrer" >
                            <img src={SIImg } alt="product hunt, InsightAI" />
                        </a>
                        <a className='banner-tags__tag ondc' href="https://www.startupindia.gov.in/" target="_blank" rel="noopener noreferrer" >
                            <img src={ ONDCImg} alt="product hunt, InsightAI" />
                        </a> */}

                        <span className='banner-tags__icons'>
                            <img src={Tally} alt="tally imtegration, InsightAI" />

                        </span>
                        <span className='banner-tags__icons'>
                            <img src={Zoho} alt="zoho integration, InsightAI" />

                        </span>
                        <span className='banner-tags__icons'>
                            <img src={FresBooks} alt="fresh books, InsightAI" />

                        </span>

                    </div>
                    {/* <Newsletter /> */}



                </div>
                <div className="home-banner__img-box">
                    <img src={bannerImg} alt="Explore the dynamic Insight AI dashboard - a visual journey through cutting-edge APIs poised to elevate your projects to new heights." />
                </div>
            </div>
            <VideoModal showVideoModal={showVideoModal} toggle={toggleVideoModal} />

        </section>
    )
}

export default Banner;
import Image1 from './img/image-1.png';
import Image2 from './img/image-2.png';
import Image3 from './img/image-3.png';
import Image4 from './img/image-4.png';
import Image5 from './img/image-5.png';
import Image6 from './img/image-6.png';

export const blogData = [
    {img:`${Image1}`, date:'01, March 2024', readingTime:'3 min', title:"Introducing InsightAI: Revolutionising Financial Crime Prevention", author:'Akash Chandra', link:'https://www.linkedin.com/pulse/introducing-insightai-revolutionising-financial-okrmc/?trackingId=PvGTa1OHTQ2x50CwUZ3cnA%3D%3D', category:'AML'},
    {img:`${Image2}`, date:'12, March 2024', readingTime:'3 min', title:"Harnessing AI for Financial Crime Prevention: A Paradigm Shift in Safeguarding Economies", author:'Vivek Kumar', link:'https://www.linkedin.com/pulse/harnessing-ai-financial-crime-prevention-paradigm-hpbxc/'},
    {img:`${Image3}`, date:'18, March 2024', readingTime:'3 min', title:"The Evolving Landscape of Financial Crime in the Digital Era", author:'Ravi Sharma', link:'https://www.linkedin.com/pulse/evolving-landscape-financial-crime-digital-era-fkoqc/?trackingId=wDYn38XTRea1hukfiHtyHw%3D%3D'},
    {img:`${Image4}`, date:'20, March 2024', readingTime:'3 min', title:"The Power of AI and Machine Learning in Case Investigations", author:'Akash Chandra', link:'https://www.linkedin.com/pulse/power-ai-machine-learning-case-investigations-4f4ke/?trackingId=jbBmMR4j8ajCSzD2llREVw%3D%3D'},
    {img:`${Image5}`, date:'20, March 2024', readingTime:'4 min', title:"Enhanced Financial Crime Detection Using Graph Analytics", author:'Shivani Pande', link:'https://www.linkedin.com/pulse/enhanced-financial-crime-detection-using-graph-joxbc/?trackingId=QZU82EF%2FMx9NOTIoSN2T4Q%3D%3D'},
    {img:`${Image6}`, date:'21, March 2024', readingTime:'4 min', title:"Leveraging Big Data for Enhanced Financial Security", author:'Akash Chandra', link:'https://www.linkedin.com/pulse/leveraging-big-data-enhanced-financial-security-qsuvc/'},
]

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//data
import { blogData } from './data';

//react-strap

//icons

import RecentPostCard from "./RecentPostCard";

//img
import Image1 from './img/image-1.png';
import BlogSlide from "../../components/BlogSlider/BlogSlide";

import Feedback from "../../components/Feedback";

//axios
import axios from "axios";
// import { Loader } from "react-feather";
import sanitizeHtml from 'sanitize-html';


const BlogSingle = () => {
    const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [blogContent, setBlogContent] = useState('');
    const { id } = useParams();


    useEffect(() => {
        setShowLoader(true)
        axios.get(`https://dev-app.insightai.in:8080/blog/retrieve_post?id=${id}`).then(res => {
            if (res && res.data) {

                setData(res.data)
                setShowLoader(false)
                setBlogContent(res?.data?.content);
            }

        }).catch(err => {
            setShowLoader(false);
            setError(true);

        })
    }, [])

    // Sanitize the HTML content
    const sanitizedHtml = sanitizeHtml(blogContent);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div className="blog-single">
            <div className="container">
                <div className="blog-single__wrapper">
                    <div className="blog-single__content">
                        {
                            showLoader ?
                                <div className='loader-wrapper'>
                                    <svg width="100" height="100" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#926BFF" data-testid="oval-svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <g transform="translate(1 1)" stroke-width="2" data-testid="oval-secondary-group">
                                                <circle stroke-opacity=".5" cx="0" cy="0" r="20" stroke="#926bffa1" stroke-width="2"></circle>
                                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                                </path>
                                            </g>
                                        </g>
                                    </svg>

                                </div>
                                : error ?
                                    <h2 className="error-message">
                                        No Data Found...
                                    </h2>
                                    : <>

                                        <span className="mini-text">{data.category || ''}</span>
                                        <h2 className="main-title-50">{data.title || ''}</h2>
                                        <div className="blog-info">
                                            {/* <span className="date">AUGUST 21, 2023</span> */}
                                            <span className="date">{formatDate(data?.created_at) || ''}</span>

                                        </div>

                                        <div className="blog-single__content__img-box">
                                            <img src={data.image || ''} alt={data.title || ''} />
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}>

                                            {/* {data.content || ''} */}
                                        </div>

                                        <Feedback />
                                    </>
                        }

                    </div>
                    <div className="blog-single__sidebar">

                        <div className="blogs-categories">
                            <h2 className="sub-subtitle">Categories</h2>
                            <div className="blogs-categories__list">
                                <span className="category-link">AML</span>
                                <span className="category-link">Risk Resiliency</span>
                                <span className="category-link">AI</span>
                                <span className="category-link">KYC</span>
                                <span className="category-link">Entity Resolution</span>
                                <span className="category-link">Data Quality</span>
                                <span className="category-link">Financial Crime</span>
                                <span className="category-link">Decision Intelligence</span>
                                <span className="category-link">Big Data</span>
                                <span className="category-link">ML</span>
                                <span className="category-link">Graph Analytics</span>

                            </div>
                        </div>
                        <div className="recent-posts">
                            <h2 className="sub-subtitle">Recent posts</h2>

                            {
                                filteredBlogPosts.map((item, i) => {
                                    return (
                                        <RecentPostCard key={i} data={item} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="related-posts">
                <div className="container">
                    <h2 className="subtitle">Related posts</h2>
                    <div className="related-posts__wrapper">
                        {
                            blogData.slice(0, 3).map((item, i) => {
                                return (
                                    <BlogSlide data={item} key={i} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogSingle;
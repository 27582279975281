import NumberAnimation from "./NumberAnimation";
const FeatureCount = ()=>{
    return(
        <div className="feature-count">
            <div className="feature-count__card">
                <div className="feature-count__count">
                    <NumberAnimation endValue={30} />%
                </div>
                <span className="feature-count__description">Time Saved on Manual Data Entry</span>
            </div>
            <div className="feature-count__card">
                <div className="feature-count__count">
                    <NumberAnimation endValue={30} />%
                </div>
                <span className="feature-count__description">Operational Cost Reduction</span>
            </div>
            <div className="feature-count__card">
                <div className="feature-count__count">
                    <NumberAnimation endValue={99} />%
                </div>
                <span className="feature-count__description">Accuracy in Data Extraction</span>
            </div>
            <div className="feature-count__card">
                <div className="feature-count__count">
                    <NumberAnimation endValue={100} />%
                </div>
                <span className="feature-count__description">Automated Compliance Checks</span>
            </div>
        </div>
    )
}

export default FeatureCount;
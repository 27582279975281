import { Link } from "react-router-dom";
import CenterContent from "../CenterContent"


const InnerPageBanner = ({miniText,title, spanText, spanIndex, content }) => {
  

          // Split the text into two parts: before and after the span
  const textBeforeSpan = title.slice(0, spanIndex);
  const textAfterSpan = title.slice(spanIndex + spanText.length);
    return (
        <section className="inner-page-banner">
            <div className="container">
                <div className="center-content">
                    {miniText && <span className="mini-text">{miniText}</span>}
                    <h2 className="title">{textBeforeSpan} <span>{spanText || ''}</span> {textAfterSpan}</h2>
                    <p className="large-paragraph">{content || ''}</p>
                </div>

                {/* <div className="inner-page-banner__nav">
                    <Link to="/" className="link" >Home</Link> <span>></span>
                </div> */}
            </div>
        </section>
    )
}
export default InnerPageBanner;

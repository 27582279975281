import DummyImg from './img/dummy.png'
import MultiFunctionImage from '../../assets/banner/6884080.jpg'

const StickyBlock = ({ reverseClass }) => {
    return (
        // we need to add "reverse class to change the order"
        <section className={reverseClass ? "sticky-block reverse" : "sticky-block"}>
            <div className="container">
                <div className="sticky-block__wrapper">
                    <div className="sticky-block__content">
                        <div className=" layer first-layer">
                            <span className="mini-text">Main Features</span>
                            <h2 className="title"><span>Four Reasons Why InsightAI</span>  is Perfectly Optimized for Your Needs</h2>

                            <h2 className="subtitle">Instant Data Analysis</h2>
                            <ul>
                                <li>Analyze large volumes of financial data in minutes.</li>
                                <li>Effortlessly handle data from databases, images, big data, ledgers, and bookkeeping software.</li>
                                <li>Save up to 50% of the time spent on manual data entry.</li>
                                <li>Ensure high accuracy and efficiency in data processing.</li>
                            </ul>
                        </div>
                        <div className="layer second-layer">
                            <h2 className="subtitle">Boost Revenue</h2>
                            <ul>
                                <li>Reduce operational costs by 30% through advanced automation.</li>
                                <li>Increase productivity and efficiency, enabling your organization to work four times faster.</li>
                                <li>Focus on strategic initiatives with time saved from routine tasks.</li>
                                <li>Drive higher revenue with optimized workflows and reduced overheads.</li>
                            </ul>
                        </div>
                        <div className="layer third-layer">
                            <h2 className="subtitle">Precision Accuracy</h2>
                            <ul>
                                <li>Eliminate human errors in data calculations and analysis.</li>
                                <li>Benefit from automated, accurate data extraction and analytics.</li>
                                <li>Ensure consistent and reliable financial insights for better decision-making.</li>
                                <li>Improve data quality and integrity across all operations.</li>
                            </ul>
                        </div>
                        <div className="layer fourth-layer">
                            <h2 className="subtitle">Seamless Compliance</h2>
                            <ul>
                                <li>Automate compliance checks to ensure adherence to taxation laws.</li>
                                <li>Instantly monitor and address any compliance issues.</li>
                                <li>Reduce the risk of penalties and non-compliance.</li>
                                <li>Simplify reporting processes with real-time compliance tracking and automated documentation.</li>
                            </ul>
                        </div>

                    </div>
                    <div className="sticky-block__img-box">
                        <img src={MultiFunctionImage} alt='This is dummy image'/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StickyBlock;
import { Link } from "react-router-dom";

const IntegrationCard = ({img, title, content})=>{
    return(
        <div className="integration-card">
            <div className="integration-card__logo">
                <img src={img || ''} alt="/"/>
            </div>
            <h2 className="subtitle">{title || ''}</h2>
            <p>{content || ''}</p>
            {/* <Link to="/" className="link" ><span>Learn More</span></Link> */}
        </div>
    )
}

export default IntegrationCard;
const HelpPageBanner = () => {
    return (
        <div className="help-banner">
            <div className="container">

                <h1 className="main-title-50">Got questions? This way.</h1>
                <div className="help-banner__wrapper">
                    <p className="large-paragraph">FAQs, quick fixes, and official info on every feature.</p>
                    <p className="large-paragraph">Can't find your question here, try our support forums.</p>
                </div>
            </div>
        </div>
    )
}

export default HelpPageBanner;
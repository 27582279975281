import Banner from "../../components/Banner";
import PlainBanner from "../../components/Banner/PlainBanner";
import BlogSlider from "../../components/BlogSlider";
import Customers from "../../components/Customers";
import FAQ from "../../components/FAQ";
import HowItWork from "../../components/HowItWorks";
import Integration from "../../components/Integration";
import News from "../../components/News";
import OurAI from "../../components/OurAI";
import StickyBlock from "../../components/StickyBlock";
import Testimonial from "../../components/Testimonials";
import Visuals from "../../components/Visuals";
import Recognitions from "../../components/Recoginition";

const Home = ()=>{
    return(
        <>
        <Banner />
        {/* <PlainBanner /> */}
        <Recognitions />
        <Customers />
        <HowItWork />
        {/* <StickyBlock /> */}
        <StickyBlock reverseClass="reverse"/>
        <Visuals />
        <Integration />

        <OurAI />
        {/* <FAQ /> */}
        <BlogSlider />
        {/* <Testimonial /> */}

        {/* <News /> */}
        </>
    )
}

export default Home;
import { Link } from "react-scroll";
import { useState } from "react";

const StickyBar = () => {
    const [activeItem, setActiveItem] = useState(null);

    const handleSetActive = (to) => {
        setActiveItem(to);
    };
    return (
        <div className="sticky-bar">
            <div className="container">
                <div className="sticky-bar__wrapper">
                    <Link to='document-intelligence' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" onSetActive={handleSetActive} className={activeItem === "section-1" ? "sticky-bar__link active" : "sticky-bar__link"}>
                        <span className="icon"></span>
                        <span className="text">WizDoc</span>
                    </Link>
                    <Link to='financial-statement-analysis' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" onSetActive={handleSetActive} className="sticky-bar__link">
                        <span className="icon"></span>
                        <span className="text">Advanced Data Reconciliation</span>
                    </Link>
                    <Link to='bank-statement-analysis' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" onSetActive={handleSetActive} className="sticky-bar__link">
                        <span className="icon"></span>
                        <span className="text">Financial Data Analytics</span>
                    </Link>

                    <Link to='ai-chatbot' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" onSetActive={handleSetActive} className="sticky-bar__link">
                        <span className="icon"></span>
                        <span className="text">GenAI Chatbot</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default StickyBar;
import { useState } from 'react';
// import { Play } from 'react-feather';

import VideoModal from '../Modals/VideoModal';

const VideoBlock = ()=>{
    const [showVideoModal, setShowVideoModal] = useState(false);
    
    const toggleVideoModal = ()=>{
        setShowVideoModal(prev => !prev);
    }
    return(
        <section className="video-block">
            <div className="container">
                <div className="video-block__wrapper">
                    <img src="https://insightai-public-images.s3.ap-south-1.amazonaws.com/group-min.webp" alt='InsightAI Team' />
                    {/* <button className='play-btn' onClick={toggleVideoModal}><span className='play-btn__wrapper'><Play /></span></button> */}
                </div>
            </div>
            <VideoModal showVideoModal={showVideoModal} toggle={toggleVideoModal} />
        </section>
    )
}

export default VideoBlock;
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import { GoogleMap, Marker, useJsApiLoader, useLoadScript, } from '@react-google-maps/api';

const ContactMap = () => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCJ_6g6yOZBGruZm40wcprU1Wqi9k44Qw8'
    });
    const center = {
        lat: 28.53450,
        lng: 77.34739
    };
    

    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    const mapOptions = {
        styles: [
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{ color: '#ebeef1' }],
            },
            {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [{ color: '#ffffff' }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#ebeef1' }], // Change street color
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            // Add more custom styles as needed
        ],
        mapTypeControl: false, // Hide map and satellite buttons
    };
    // const Marker = () => <div className="marker"></div>;

    return (
        <div className='contact-map'>
            {
                isLoaded ?
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        options={mapOptions}
                        zoom={18}
                        className="map"
                    >
                        <Marker position={center}  className="marker"/>
                    </GoogleMap>

                    : <></>
            }
        </div>

    )
}

export default ContactMap;
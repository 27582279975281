import { useState, useEffect } from "react";
import RecentPostCard from "./RecentPostCard";


const RecentPosts = ({ data }) => {
    const [recentPosts, setRecentPosts] = useState([]);

    useEffect(() => {
        // Function to filter blog posts published within the current week
        const filterPostsForCurrentWeek = () => {
            const currentDate = new Date();
            const currentWeekStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay()); // Start of current week (Sunday)
            const currentWeekEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (6 - currentDate.getDay())); // End of current week (Saturday)

            return data.filter(blogPost => {
                const postDate = new Date(blogPost.created_at);
                return postDate >= currentWeekStart && postDate <= currentWeekEnd;
            });
        };

        const filteredPosts = filterPostsForCurrentWeek();

        setRecentPosts(filteredPosts);
    }, [data]);


    return (
        <div className="recent-posts">
            <h2 className="sub-subtitle">Recent Posts</h2>
            {recentPosts.length > 0 ?
                recentPosts.slice(0, 3).map(item => (
                    <RecentPostCard key={item.id} data={item} />
                ))
                : <span className="error-msg">No recent posts</span>
            }
        </div>
    )
}

export default RecentPosts;
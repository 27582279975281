import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { X } from 'react-feather';

const VideoModal = ({ showVideoModal, toggle }) => {
    return (
        <>
            <Modal isOpen={showVideoModal} toggle={toggle} className="video-modal">
                <button className='close-btn' onClick={toggle}><X /></button>
                <ModalBody>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/x2nXdtGtz3Y?si=0WezbZOhSI4pN3Tn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </ModalBody>
            </Modal>
        </>
    )
}
export default VideoModal;

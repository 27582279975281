import fsaImg from './img/fsa.jpg';


import ImageTextCol from "./ImageTextCol";
import StickyBar from "./stickyBar";

// Feature Image
import reconcileImg from '../../assets/feature/reconciliation.png';
import analyticsImage from '../../assets/feature/financial_analytics.png';
import documentImg from '../../assets/feature/doc_intelligence.png';
import chatbotImg from '../../assets/feature/bot.png';

const SolutionsBlock = () => {
    const data = [
        { id: 'document-intelligence', title: 'WizDoc', subtitle: 'Document Intelligence', content: '', list: ['AI-Powered Insights: Utilize genAI to transform document data into actionable insights.', 'Multi-Format Compatibility: Process data from Excel, images, documents, PDFs, and more.', 'Complex Query Handling: Execute intricate queries to uncover critical results with ease.', 'Intelligent Automation: Automate data extraction and analysis for increased efficiency.'], img: `${documentImg}`, reverse: false },

        { id: 'financial-statement-analysis', title: 'Advanced Data Reconciliation', subtitle: 'Financial Statement Analysis', content: '', list: ['Seamless Integration: Easily connect with major bookkeeping solutions like Tally and Zoho for effortless data syncing.', 'Instant Invoice Analysis: Quickly analyze bulk invoices to extract user data and insights.', 'Precise Ledger Analytics: Obtain detailed analytics for ledger data and comprehensive data reconciliation.', 'Automated Reporting: Generate accurate, professional reports for customers with a click.'], img: `${reconcileImg}`, reverse: true },

        { id: 'bank-statement-analysis', title: 'Financial Data Analytics', subtitle: 'Financial Data Analytics', content: '', list: ['Effortless Statement Analysis: Simplify the review of large financial statements for comprehensive insights.', 'Performance Ratios: Discover key financial ratios to gauge company performance accurately.', 'Versatile Formats: Analyze statements in various formats, including scanned documents and PDFs.', 'Downloadable Reports: Create and download detailed Excel or PDF reports for easy sharing.',], img: `${analyticsImage}`, reverse: false },



        { id: 'ai-chatbot', title: 'GenAI Chatbot', subtitle: 'GenAI Chatbot', content: '',list: ['Superior Chatbot Solution: Experience the best no-close bot for handling customer queries.', 'Instant Knowledge Base: Simply upload data to create an immediate, comprehensive knowledge base.', 'Human-Like Responses: Deliver answers in natural, human language for enhanced user experience.', 'Tailored Service: Provide personalized responses to each client, ensuring full data security and relevance.'],  img: `${chatbotImg}`, reverse: true },
    ]

    return (
        <section className="solutions-block">
            <StickyBar />
            <div>
                {
                    data.map((item, i) => {
                        return (
                            <ImageTextCol data={item}/>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default SolutionsBlock;
// App.js
import React from 'react';
import StickySection from '.';
import dummyImg from './img/Banner.png';
import recommendation from './img/InsightAi.gif';
import phone from './img/phone.png';
import langauge from './img/language-mic.png';
import integration from './img/integration.png';
import phoneUsage from './img/phone-2.png';
import wedding from './img/wedding.png';

function Feature() {
    return (
        <div className="App">
            <StickySection id="section1">
                <div className='sticky-feature'>
                    <div className='container'>


                        <div className='sticky-feature__graphic'>
                            <img src={recommendation} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Get tailored recommendations from simple language queries.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic mobile'>
                            <img src={phone} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Snap and Shop – Find matching items with a single photo.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic'>
                            <img src={langauge} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Search in your preferred language for a global reach.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic'>
                            <img src={integration} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Effortlessly integrate with your existing platforms.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic'>
                            <img src={phoneUsage} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Shop on the go with our mobile-friendly interface.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic'>
                            <img src={recommendation} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Enjoy tailored suggestions based on your shopping history.</h2>
                        </div>
                    </div>
                </div>
                <div className='sticky-feature'>
                    <div className='container'>
                        <div className='sticky-feature__graphic'>
                            <img src={wedding} alt='/' />
                        </div>
                        <div className='sticky-feature__content'>
                            <h2 className='main-title'>Find the perfect products for any occasion.</h2>
                        </div>
                    </div>
                </div>

            </StickySection>
        </div>
    );
}

export default Feature;

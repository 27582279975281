const AkashImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/Akash.webp";
const  KhushbooImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/khushboo.webp";
const  SheshImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/shesha-2.webp";
const  ShivaniImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/shivani.webp";
const  VivekImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/vivek.webp";
const  DishuImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/dishu.webp";
const  ArpitaImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/arpita.webp";
const  AshwaniImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/Ashwani.webp";
const  RashmiImg = "https://insightai-public-images.s3.ap-south-1.amazonaws.com/Rashmi.webp";



export const teamData = [
    {name:'Akash Chandra', position:'Founder & CEO',linkedIn:'https://www.linkedin.com/in/akash-chandra-centerstageai/',twitter:'https://twitter.com/getInsightAI', img:`${AkashImg}`, instagram:''},
    // {name:'Rashmi Mahadevaiah', position:'Co-founder',linkedIn:'https://www.linkedin.com/in/rashmimahadevaiah/',twitter:'', img:`${RashmiImg}`, instagram:''},
    // {name:'Girish Gandhi', position:'Lead Product Engineer ',linkedIn:'https://www.linkedin.com/in/girish-g-b8b376169/',twitter:'https://twitter.com/getInsightAI', img:`${GirishImg}`, instagram:''},
    {name:'Vivek Kumar', position:'Lead Machine Learning Engineer',linkedIn:'https://www.linkedin.com/in/vivek-kumar-ai-ml/',twitter:'https://twitter.com/getInsightAI', img:`${VivekImg}`, instagram:''},
    {name:'Shesha Sahu', position:'Backend Software Engineer',linkedIn:'https://www.linkedin.com/in/shesha3291/',twitter:'https://twitter.com/getInsightAI', img:`${SheshImg}`, instagram:'https://www.instagram.com/shesha3291?igsh=MWQ0Znk1eTM1ZnhzMA=='},
    {name:'Khushboo Singh', position:'Frontend Developer',linkedIn:'https://www.linkedin.com/in/khushboo-singh-894879165/',twitter:'https://twitter.com/getInsightAI', img:`${KhushbooImg}`, instagram:''},
    {name:'Shivani Pande', position:'Data Science Engineer',linkedIn:'https://www.linkedin.com/in/shivani-pande-0aa959235/',twitter:'https://twitter.com/getInsightAI', img:`${ShivaniImg}`, instagram:''},
    {name:'Dishu Yadav', position:'Sales intern',linkedIn:'https://www.linkedin.com/in/dishu-yadav-a5a831221/',twitter:'https://twitter.com/iyaadavdisha', img:`${DishuImg}`, instagram:'https://www.instagram.com/iyaadavdisha?igsh=OGNkY3BpMmtyNWQw'},
    {name:'Arpita Panja', position:'Sales Head',linkedIn:'https://www.linkedin.com/in/arpita-panja-846a163/',twitter:'', img:`${ArpitaImg}`, instagram:''},
    {name:'Ashwani Singh', position:'Data Science Intern',linkedIn:'https://www.linkedin.com/in/ashwani-singh-3b64b81b3',twitter:'', img:`${AshwaniImg}`, instagram:''},


]
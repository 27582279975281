import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import SolutionsBlock from "../../components/SolutionsBlock";
import KeyBenefits from "../../components/KeyBenefits";
import CenterContent from "../../components/CenterContent";


const Solutions = () => {
    const bannerData = {
        miniText: '',
        titleText: 'Enhance Financial Operations with Advanced Data Solutions',
        spanText: 'Advanced Data Solutions',
        content: 'Revolutionize financial data with seamless integration, AI-powered analytics, and intelligent automation.',
        spanIndex: 'Enhance Financial Operations with Advanced Data Solutions'.indexOf('Advanced Data Solutions')
    };

    return (
        <>
            <InnerPageBanner
                miniText={bannerData.miniText}
                title={bannerData.titleText}
                spanText={bannerData.spanText}
                spanIndex={bannerData.spanIndex}
                content={bannerData.content}
            />

            <SolutionsBlock />
            <KeyBenefits />
            {/* <div className="getstarted-block">
                <div className="container">
                    <CenterContent miniText="Get Started" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Optimize all your images effortlessly right from your admin dashboard and experience the power of speed." btnText="Get started - It's free"/>

                </div>

            </div> */}
        </>
    )
}

export default Solutions;
import { Linkedin } from "react-feather";
import MediumIcon from "./img/medium-icon.svg";
import { Share2 } from "react-feather";

const BlogSlide = ({ data }) => {
    const { img, date, readingTime, title, author, link } = data;

    // const maxWords = 6;
    // const truncatedTitle = data.title.split(' ').slice(0, maxWords).join(' ') + (data.title.split(' ').length > maxWords ? '...' : '');

    const handleShareButtonClick = async () => {
        const linkToShare = typeof link === 'string' ? link : JSON.stringify(link);

        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check out this blog!',
                    text: 'I found this interesting blog, take a look:',
                    url: linkToShare,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.warn('Web Share API is not supported');
            // Fallback: Copy the link to the clipboard
            try {
                await navigator.clipboard.writeText(linkToShare);
                alert('Link copied to clipboard');
            } catch (error) {
                console.error('Failed to copy link:', error);
            }
        }

    };

    return (
        <div className="blog-slide">
            {/* <a href={link || ''} target="_blank" rel="noopener noreferrer" className="blog-slide__link"> */}
            <div className="blog-slide__img">
                <img src={img || ''} alt="blog-img" />
            </div>
            <div className="blog-slide__content">
                <div className="post-time">
                    <span className="post-date">{date || ''} |</span>
                    <span className="post-read">{readingTime || ''} Read</span>
                </div>
                <h2 className="blog-slide__title">{title || ''} </h2>
                <span className="post-writer">{author || ''}</span>

                <div className="blog-slide__social">
                    <span className="blog-slide__social__title">Read on: </span>
                    <div className="blog-slide__social__wrapper">
                        <a href={link || ''} target="_blank" rel="noopener noreferrer"><span className="mini-text">LinkedIn</span></a>

                        <a href={link || ''} target="_blank" rel="noopener noreferrer"><span className="mini-text">Medium</span> </a>
                    </div>

                    <button className="share-btn" onClick={handleShareButtonClick}><span className="mini-text share"><Share2 /></span></button>

                    {/* <span className="blog-slide__social__title">View on:</span>
                        <a href={link || ''} target="_blank" rel="noopener noreferrer" className="share-link"><Linkedin /></a>
                        <a href="" target="_blank" rel="noopener noreferrer" className="share-link">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="60px" height="60px"><path d="M8.5 7A8.5 8.5 0 108.5 24 8.5 8.5 0 108.5 7zM22 8A4 7.5 0 1022 23 4 7.5 0 1022 8zM28.5 9A1.5 6.5 0 1028.5 22 1.5 6.5 0 1028.5 9z" /></svg>
                        </a> */}
                </div>
            </div>
            {/* </a> */}
        </div>
    )
}

export default BlogSlide;
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

const Feedback = () => {
    return (
        <section className="feedback">
            <h2 className="subtitle">
                Feedback Corner
            </h2>
            <Form className="feedback-form">
                <FormGroup>
                    <Input
                        id="user-message"
                        name="message"
                        placeholder="Message"
                        // value={}
                        // onChange={}
                        type="textarea"
                        required
                    />
                </FormGroup>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Input id="name" name="name" placeholder="Name" />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Input id="name" name="email" placeholder="Email" />
                        </FormGroup>
                    </Col>
                </Row>
                <button className="btn btn-dark" data-text="Post Comment"><span className="btn-wrapper">Post Comment</span></button>
            </Form>
        </section>
    )
}

export default Feedback;
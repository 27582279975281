import { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

const NumberAnimation = ({ endValue }) => {
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const [number, setNumber] = useState(0);
    const animatedRef = useRef(null);

    const intersectionOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // When 50% of the element is visible
    };

    const intersectionCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !animationTriggered) {
                setAnimationTriggered(true);
                setNumber(endValue);
            }
        });
    };


    const { numberValue } = useSpring({
        numberValue: animationTriggered ? endValue : 0,
        from: { numberValue: 0 },
        delay: 500, // Optional delay before animation starts
    });

    useEffect(() => {
        const observer = new IntersectionObserver(intersectionCallback, intersectionOptions);
        if (animatedRef.current) {
            observer.observe(animatedRef.current);
        }
        return () => {
            if (animatedRef.current) {
                observer.unobserve(animatedRef.current);
            }
        };
    }, [animatedRef, intersectionOptions]);

    return (
        <animated.span ref={animatedRef}>
            {numberValue.interpolate((val) => Math.floor(val))}
        </animated.span>
    );
};

export default NumberAnimation;

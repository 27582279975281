import CenterContent from "../CenterContent";
import Banks from './img/Bank_Final.jpg';
import NBFCS from './img/NBFC.jpg';
import Audit from './img/Audit_Firms.jpeg';
import Ecommerce from './img/E_commerance.jpg';
import Insurance from './img/Insurance_2.jpg';
import PaymentGateway from './img/Payment_Gateway_1.jpg';

const Customers = () => {
    // Define the data
    const titleText = 'Those We Support';
    const spanText = 'We Support';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);

    return (
        <section className="customers">
            <div className="container">
                <CenterContent miniText="industries" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Maximize your financial institution's potential with our specialized solutions and support." />
            </div>
            <div className="customers__wrappers">
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={Banks} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">Banks</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">Banks</h2>
                        <p>Enhance security with fraud detection, AML solutions, and document intelligence. Elevate customer service with GenAI-powered solutions.</p>
                    </div>
                </div>
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={NBFCS} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">NBFCS</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">NBFCS</h2>
                        <p>Improve bank statement analysis and fraud detection. Utilize document intelligence and GenAI-powered customer service for seamless operations. </p>
                    </div>
                </div>
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={Audit} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">Accounting Firms</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">Accounting Firms</h2>
                        <p>Streamline accounting reconciliation and financial data analytics. Use GenAI bots for advanced customer service and comprehensive analysis. </p>
                    </div>
                </div>
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={Ecommerce} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">Ecommerce</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">Ecommerce</h2>
                        <p>Leverage data analytics and a product recommendation bot to enhance customer experiences and boost sales.</p>
                    </div>
                </div>
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={Insurance} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">Insurance Companies</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">Insurance Companies</h2>
                        <p>Boost customer service with product recommendation bots. Enhance financial document analytics for better decision-making.</p>
                    </div>
                </div>
                <div className="customer-card">
                    <div className="customer-card__img">
                        <img src={PaymentGateway} alt="/" />
                    </div>
                    <h2 className="customer-card__main-title">Payment Gateways</h2>
                    <div className="customer-card__content">
                        <h2 className="customer-card__title">Payment Gateways</h2>
                        <p>Implement robust document analysis and fraud detection solutions to secure transactions and streamline operations.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Customers;
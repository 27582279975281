// Center Content component
import CenterContent from "../CenterContent";
import WorkCards from "./WorkCard";

//img

import DIimg from './img/data-ingestion.png';
import DAimg from './img/data-analysis.png';
import Reportimg from './img/report.png';


const workData = [
    { step: '1', icon: `${DIimg}`, title: 'Data Ingestion', content: 'Effortlessly ingest data from databases, images, big data, ledgers, and bookkeeping software.' },
    { step: '2', icon: `${DAimg}`, title: 'Data Analysis', content: 'Reveal crucial patterns, trends, and insights for smart decision-making and strategic planning.' },
    { step: '3', icon: `${Reportimg}`, title: 'Report', content: 'After completing the data analysis, the final step entails generating a comprehensive report, summarizing findings and recommendations for actionable insights.' },
]


const HowItWork = () => {
    // Define the data
    const titleText = 'Seamless Integration';
    const spanText = 'Integration';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);


    return (
        <section className="how-it-work">
            <div className="container">
                <CenterContent miniText="how it works" title={titleText} spanText={spanText} spanIndex={spanIndex} content=" Effortless integration for advanced analytics. No technology expertise required for a seamless experience."/>

                <div className="how-it-work__grid">
                    {
                        workData.map((item, i) => {
                            return (
                                <WorkCards data={item} key={i} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default HowItWork;
import video from './img/how-it-work.gif';

const Video = ()=>{
    return(
        <div className="video-section">
            <div className="container">
                <div className="video-section__inner">
                    <img src={video} alt='how it works?'/>
                </div>
            </div>
        </div>
    )
}

export default Video;